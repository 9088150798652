// Angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AppRoutingModule } from './app-routing.module';

// app components
import { AppComponent } from './app.component';
import { UtilityService } from './services/utility/utility.service';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {
  RemarketingUiComponentsLibModule,
  TooltipComponent,
  AssetsModule
} from 'remarketing-ui-components-lib';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {
  AmbassadorState,
  AuctionSummaryState,
  AuthenticationState,
  AuthGuard,
  AuthService,
  BackLinkState,
  CarfaxState,
  ConfigurationService,
  ConfigurationState,
  FinanceState,
  LeadBuyerState,
  OfferListState,
  OrganizationState,
  RemarketingAngularLibraryModule,
  TransportationState,
  TransportationHubState,
  UserProfileState,
  VDPConfigurationState,
  VehicleProcessingState,
  LoggerService,
  AnalyticsState
} from 'remarketing-angular-library';

// Auth
import { OktaAuthGuard, OKTA_CONFIG, OktaAuthModule, OktaAuthService } from '@okta/okta-angular';
import { RegistrationCancelModalComponent } from './modules/registration/components/registration-cancel-modal/registration-cancel-modal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserSuggestionModule } from './modules/browser-suggestion/browser-suggestion.module';
import { NgxMaskModule } from 'ngx-mask';
import {
  CoreModule,
  WebsocketService,
  liveEngageTagProvider,
  AppIdleService,
  IdleConfig,
  SessionModule,
  PipeModule,
  windowProvider,
  SessionRefreshFactory,
  TranslocoRootModule,
  AppInitService,
  OktaAuthConfig,
  WhiteLabelTokenService,
  SmartauctionFrontendMicroLibraryModule,
  AuthInterceptor,
  WhiteLabelCallbackGuard,
  AppInstanceService,
  ErrorsModule,
  LogoutModule,
  LoginModule,
  AppState,
  LoginState,
  CustomBsModalServiceService
} from 'smartauction-frontend-micro-library';
import { environment } from 'src/environments/environment';
import * as SockJS from 'sockjs-client';
import { OffersModule } from '../sell/lib/offers/offers.module';
import { TranslocoService } from '@ngneat/transloco';
import { NgxPrintModule } from 'ngx-print';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import * as OktaAuthConstructor from '@okta/okta-auth-js';
import allytm from '@allysf/allytm';

const idleConfig: IdleConfig = {
  keepAliveTime: 28800, // 480 mins
  idleTime: 28740, // 479 mins
  idleTimeOut: 59, // 01 mins
  sessionPingTime: 900000 // 15 mins
};

// Setup instance of AuthService.
AuthService.setup();

export function websocketInitConfig() {
  const websocketConfig: any = {
    reconnectDelay: environment.websocket.reconnectDelay,
    reconnectInterval: environment.websocket.reconnectInterval,
    webSocketFactory: () => {
      const reserved = undefined;
      const options = {
        transports: [
          'websocket',
          'hybi-10',
          'rfc6455',
          'hixie-76',
          'xhr-streaming',
          'xdr-streaming',
          'xhr-polling',
          'xdr-polling',
          'eventsource'
        ]
      };
      return new SockJS('/api/websocket/remarketing', reserved, options);
    },
    beforeConnect: client => {
      if (!environment.websocket.isEnabled) {
        client.deactivate();
      }
    },
    debug: msg => {
      WebsocketService.log(msg);
    }
  };
  return websocketConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    RegistrationCancelModalComponent
  ],
  imports: [
    RemarketingAngularLibraryModule.forRoot(environment),
    SmartauctionFrontendMicroLibraryModule,
    RemarketingUiComponentsLibModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ErrorsModule,
    LoginModule,
    BrowserSuggestionModule,
    LogoutModule,
    CommonModule,
    CoreModule.forRoot({
      websocketFactory: websocketInitConfig
    }, environment, allytm),
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OktaAuthModule,
    SessionModule,
    AssetsModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxsModule.forRoot(
      [
        AppState,
        ConfigurationState,
        LoginState,
        UserProfileState,
        OrganizationState,
        AuthenticationState,
        FinanceState,
        LeadBuyerState,
        AnalyticsState,
        BackLinkState,
        VDPConfigurationState,
        CarfaxState,
        OfferListState,
        TransportationState,
        TransportationHubState,
        AuctionSummaryState,
        VehicleProcessingState,
        AmbassadorState
      ],({})
    ),
    NgxsStoragePluginModule.forRoot({
      storage: 1,
      key: [
        'appState',
        'authentication',
        'configuration',
        'login',
        'org',
        'user',
        'vdpConfiguration',
        'leadBuyer',
        'analytics',
        'sellConfiguration',
        'backLink',
        'carfax',
        'transportation',
        'transportationHub',
        'bsoConfiguration',
        'auctionSummary',
        'auctionSummaryFilters',
        'search',
        'advancedFilters',
        'sellToggle',
        'offerListToggle',
        'offerList',
        'sharedListToggle',
        'bidListToggle',
        'editPosting',
        'ambassador'
      ]
    }),
    RemarketingAngularLibraryModule.forRoot(environment),
    PipeModule.forRoot(),
    AccordionModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxSpinnerModule,
    TypeaheadModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: true }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslocoRootModule,
    TranslocoLocaleModule,
    MatSlideToggleModule,
    OffersModule,
    NgxPrintModule
  ],
  providers: [
    {
      //okta session refresh
      provide: 'OktaAuth',
      useValue: OktaAuthConstructor,
    },
    {
      provide: OKTA_CONFIG,
      deps: [OktaAuthConfig, LoggerService],
      useFactory: (
        oktaConfig: OktaAuthConfig,
        loggerService: LoggerService
      ) => {
        oktaConfig.config.tokenManager = {
          secure: true,
          storage: 'sessionStorage',
          renew: false
        };
        oktaConfig.config.redirectUri = location.origin + '/auth-user/callback';
        loggerService.log('* Oktaconfig', oktaConfig.config);
        return { ...oktaConfig.config };
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SessionRefreshFactory,
      deps: [ConfigurationService, TranslocoService, WhiteLabelTokenService],
      multi: true
    },
    AppInitService,
    AppIdleService,
    AppInstanceService,
    UtilityService,
    {
      provide: BsModalService,
      useClass: CustomBsModalServiceService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    WhiteLabelTokenService,
    AuthGuard,
    WhiteLabelCallbackGuard,
    windowProvider,
    OktaAuthService,
    OktaAuthGuard,
    PendingChangesGuard,
    liveEngageTagProvider,
    {
      provide: 'IDLE_CONFIG',
      useValue: idleConfig
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  static InjectorInstance: Injector;

  constructor(private injector: Injector) {
    AppModule.InjectorInstance = injector;
  }
}

import { get } from 'lodash';
import {
  ApigeeEnvironmentConfig,
  EnvironmentConfiguration,
  EnvironmentConfigurationItemOkta,
  EnvironmentConfigurationItemSamlUrls,
  EnvironmentConfigurationItemWebSocket,
  EnvironmentFeatureFlags
} from './environment.interface';

export default class Environment implements EnvironmentConfiguration {
  static readonly REGEXP_INTERNAL = /.int.ally.com/g;
  private readonly defaultFeatureFlags: EnvironmentFeatureFlags = {
    buySearchBulkSale: false
  };

  name: string;
  production: boolean;
  serverHost: string;
  serverURL: string;
  taggingSiteId: string;
  okta: EnvironmentConfigurationItemOkta;
  samlUrls: EnvironmentConfigurationItemSamlUrls;
  featureFlags: EnvironmentFeatureFlags;
  websocket: EnvironmentConfigurationItemWebSocket;
  launchDarklyKey: string;
  securityProfileUrl: string;
  isLoggingServiceEnabled: boolean;
  apigeeApiKey: string;
  apigeeBaseUrl: string;
  apigeeUATAccessTokenUrlBase?: string;
  apigeeOESPathUrl?: string;
  apigeeAuthorizationKey: string;
  batchPostingApigeeConfig: ApigeeEnvironmentConfig;

  get locationOrigin(): string {
    return window.location.origin;
  }

  get isInternal(): boolean {
    return !!this.locationOrigin.match(Environment.REGEXP_INTERNAL);
  }

  get samlUrl(): string {
    const key = this.isInternal ? 'internal' : 'external';
    return get(this, `samlUrls.${key}`);
  }

  constructor(config: EnvironmentConfiguration) {
    const { featureFlags = {}, ...rest } = config;
    Object.assign( this, rest );
    this.featureFlags = { ...this.defaultFeatureFlags, ...featureFlags};
  }
}
